// import "./Appdown.css";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import download_app from "../../assets/download_app.png";
import pngwing from "../../assets/pngwing.com.png";
import apple from "../../assets/apple.png";
import zobiz1 from "../../assets/zobiz1.png";
import { Helmet } from "react-helmet-async";
import Download from "../download/Download";
import Subscribe from "../subscribe/Subscribe";

import img1 from "../../assets/step01.png";
import img2 from "../../assets/step02.png";
import img3 from "../../assets/step03.png";
import { Navbar } from "react-bootstrap";
const ZobizAppDown = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);
  return (
    <>

    <section id="download_app" class="section-padding wow fadeIn animated"   data-wow-delay=".1s" style={{visibility: 'visible',delay:' .1s' ,delay: '.1s', animationdelay:' .1s'}}>
      <Helmet>
            <title>Download</title>
            <meta name="description" content="Simply download the Zobiz app become a partner and start ordering mobile phones at best rates." />
        </Helmet>
    <div class="container">
  <div class="row" style={{marginTop:"-100px"}}>
      <div class="col-lg-6 col-md-12 col-xs-12" data-aos="fade-right">
      <div class="wow fadeInLeft animated" data-wow-delay="0.3s" style={{visibility: 'visible', delay: '0.3s', delay:' 0.3s', animationdelay: '0.3s'}}>
        <div id="down_app">
          <div class="content text-dark text-left" style={{marginTop:"-10px"}}>
                <h1>Join India’s Most Transparent Business Partner</h1>
              <p>Simply download the <strong>ZOBIZ</strong> APP,
              become a partner and start ordering mobile phones at best rates.</p>
              <div id="down_icon"><a href="https://play.google.com/store/apps/details?id=com.zobox.zobiz" target="_blank"><img src={pngwing} alt="play"/></a> &nbsp;
              <a href="#" target="_blank"><img src={apple} alt="app"/></a></div>
          </div>
        </div>
      </div>
    </div>
      <div class="col-lg-6 col-md-12 col-xs-12 wow fadeInRight animated" data-wow-delay="0.3s" style={{visibility: 'visible' ,animationdelay:' 0.3s' ,delay: '0.3s', animationdelay: '0.3s'}}>
      <div class="img_box"><img class="img-fluid" src={zobiz1} alt="Download SuperSale App"/></div>
    </div>
  </div>
</div>
</section>

<section id="b_partner" class="section-padding" style={{marginTop:"-100px"} }>
      <div class="">
        <div class="section-header text-center" data-aos="fade-up">          
          <h2 class="section-title wow fadeInUp animated" data-wow-delay="0.5s" style={{visibility:'visible',animationdelay: '0.5s',animationdelay:' 0.5s',animationdelay:'0.5s'}}>How To Become A Partner</h2>
          <div class="shape wow fadeInLeft animated" data-wow-delay="0.6s" style={{visibility: 'visible',animationdelay:'0.6s',animationdelay:'0.6s', animationdelay: '0.6s'}}></div>
        </div>
        <div class="row" >
          <div class="col-lg-4 col-md-4 col-xs-12">
			  
            <div class="table wow fadeInLeft animated" data-wow-delay=".2s" style={{visibility: 'visible',animationdelay:'0.2s',animationdelay:'0.2s', animationdelay: '0.2s'}}>
              <div class="img-box">
				  <i>1</i>
                <img class="img-fluid" src={img1} alt="Download App" />
              </div>
              <div class="b_partner-header">
                <p class="b_title"> Fill Form For Join Us At <br/> <span>Zobox.in</span></p>
              </div>
            </div> 
          </div>
          <div class="col-lg-4 col-md-4 col-xs-12">
            <div class="table wow fadeInLeft animated" data-wow-delay=".3s" style={{visibility: 'visible',animationdelay:'0.3s',animationdelay:'0.3s', animationdelay: '0.3s'}}>
              <div class="img-box">
				  <i>2</i>
                <img class="img-fluid"src={img2} alt="Register Yourself"/>
              </div>
              <div class="b_partner-header">
                <p class="b_title"> Our Sales Person Contact And Register You As A Partner On Zobiz App </p>
              </div>
            </div> 
          </div>
		<div class="col-lg-4 col-md-4 col-xs-12">
            <div class="table wow fadeInLeft animated" data-wow-delay=".4s" style={{visibility: 'visible',animationdelay:'0.4s',animationdelay:'0.4s', animationdelay: '0.4s'}}>
              <div class="img-box">
				  <i>3</i>
                <img class="img-fluid" src={img3} alt="Login Start"/>
              </div>
              <div class="b_partner-header">
                <p class="b_title"> Login &amp; <br/> start placing orders</p>
              </div>
            </div> 
          </div>	
        </div>
      </div>
    </section>
</>
  );
};

export default ZobizAppDown;

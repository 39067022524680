import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./press.css";
import { Avatar } from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { Helmet } from "react-helmet-async";

const PreviousBtn = (props) => {
  console.log(props);
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowBackIos style={{ color: "#1f2469", fontSize: "45px" }} />
    </div>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <ArrowForwardIos style={{ color: "#1f2469", fontSize: "45px" }} />
    </div>
  );
};
const Press = () => {
  return (
    <div
      className="testimonial"
      style={{ display: "flex", justifyContent: "center", marginTop: 50 }}
    >
         <Helmet>
            <title>Zobox || SASTE MOBILE KA ADDA</title>
            <meta name="description" content="Testimonial" />
        </Helmet>
      <div  className="title" style={{ width: "50%", textAlign: "center" }}>
        <h2 style={{ marginBottom: 20 }}>Press Mentions</h2>
       
        <Slider prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} dots>
         <a href="https://timesofindia.indiatimes.com/blogs/voices/how-renewed-smartphones-are-recreating-new-segment-in-india-by-increasing-affordability-and-reducing-e-waste/"> <Card img="https://static.toiimg.com/photo/47529300.cms" />
         <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>TIMES OF INDIA</span>
      </p>
        </a>

        <a href="https://www.cxotoday.com/interviews/zobox-from-vision-to-revolution-redefining-the-refurbished-gadgets-landscape/">  
        <Card img="https://apiexcellent.com/images/digital/product/cxotoday-digital10209.webp" />
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>CXO TODAY</span>
      </p>
        </a>

        <a href="https://www.cxotoday.com/interviews/zobox-from-vision-to-revolution-redefining-the-refurbished-gadgets-landscape/">  
        <Card img="https://img.etimg.com/photo/96716870.cms" />
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>ET NOW</span>
      </p>
        </a>

        <a href="https://www.zeebiz.com/hindi/small-business/zobox-startup-journey-started-by-neeraj-chopra-know-how-it-is-reducing-e-waste-by-refurbishing-old-mobile-and-selling-them-137535">  
        <Card img="https://yt3.googleusercontent.com/ytc/APkrFKZbP3A2NEEH2KF7e5OSgryOF1ac4eh51YS6XxcQdg=s900-c-k-c0x00ffffff-no-rj" />
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>ZEE BUSINESS</span>
      </p>
        </a>

        <a href="https://cio.techgig.com/leaders-opinion/how-indian-economy-is-driven-by-refurbished-electronics-in-future/articleshow_b2b/102285608.cms">  
        <Card img="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALwAAAC8CAMAAAD1lzSZAAAAmVBMVEX///4zU4DjNDnU2+PoVVn1tLVkfJ6Wp71hep1ed5tpgaKEl7Lo7PBYcpfrcXQ4XYjzq6zN1d/63d3yo6TEzdmruMrkOj/75OTd4+l8kK3pYmbz9fdEYYqMnrdPa5HtfH/3xMXxmZvviYzwkJL509TqaW34ysrlRUm1wdHugoX98O9ziaj+9vbmSU3xnZ9IZY31urufrsOvvM0m6GJtAAAD/UlEQVR4nO2Z6ZaiMBBGccO1XVhdWlFwRUZk3v/hBoKEREmkXbrn9PnuLysUelMGCImiAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD/EbBR1jx1VVTuT+X5W8iT7vPIOTt85ePrWF2YFW91znRj3dLblX9j6KGRhKspc7dwwIifVJoMKw6DbI83jLGueRL1Oer56TDu37VcZrP4yFXCmF9LY93Zs2nQok68IiH9/UdC8T85p3jR/Gkn7KAtD0kN6lHRtVb2mHyTt1JVobv9cZ9XXYvlPgXxTUdSCZqPQPabG6rbJ/5BFg0Teu3GPy5rY17OokbgXZFlie4G7SH7PSFU6kXnMPi/k8rmVNc2Le7iSD/IhU6ft1T+BSD5Mvz/XGxAqESP/SRnElZ9T9+T8LtMtifyUVttWgqGVRTYvr2efd/EFsKad1EXyvRRq1J4RejNG3phR4jMmWfOcG+cTqTzV1ZJDThYNeXl6SZ+SrHMW9cWjnhBlv6TmbVS+xqXSWneTyGCExfI+W2vmAtAF8unFm0WbO/L0ImwVyI/KyH/I5G2+8q6g8htuoCzLVv55eWnlIf8KeXptv15+CvkH5B+6YPVS8k/dbfhbpUC+xciT++2Mla9y8oevVf6Ft0pePnvKJU81Kh82m/PmRCxvb9cpvkze317S7kyM5fLh4kLYuZVnyOeRHLfDhkUsXxqpfE74XvnNW+U/vkn+9A75z+flA82maIGw8jaTJX5lLCHfmVw4NtlUsXxoRpE5L5QP9KpFET5hz5rFZD11nx8Xp4rlyZf0iuW510FdIL9cs1kvvFWWkG9zETclDk6slqjyS43NeuETlpef1cYxV/d5mbwrkafzeb7yb5MvfMJK5O21563oC55IvuHHWZvvk7+Z2xTLv+M18Hvl+/fkyUNq+J/I52sav1fegfwvkudfRugSDy+/kMkrPyfPTw+oPL9iVlLel8kfmM+K0niJfPvIrs/TVLJWaTLCEnn6WCJvRdN78qTyq5fIM+yZgVJRzT2dOibLyhJ5Wuup5mtuVSBPa71b+na+HPsq+WR9vmjTgewgSOSZuQq783Eln/9B1Z2VZz0i3yquvDIeCA5I5PPVa64L1/KaxWdZVhl5uoYX5m1tgaMyvulWyG/rLLgo3dYZcl6eTwbSiVn8JivD6zqbNbXJQKrfkTe6c0I3ytvGtVsuG3/GhB07apQ298yUyGAj82961KZbZdZhHYeNoe5u4055K4J3ebsdUv3NOQ7PcZZ0U+0heqMEwzBGvbKnBNuT6xxWS+E2TYqmu47jDe+t1gAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABf4h+JmW62CFOy1AAAAABJRU5ErkJggg==" />
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>TECH GIG</span>
      </p>
        </a>

        <a href="https://www.timesnownews.com/technology-science/the-changing-landscape-of-indian-refurbished-smartphone-industry-heres-what-the-future-holds-article-102488851">  
        <Card img="https://yt3.googleusercontent.com/GKw3-YGSe7_0K_Y8E-cqomjUmSvqD2E7kBJXaB05QTMYONC6RjJcMkLgtm8KdqX9EEV6QwgDGw=s900-c-k-c0x00ffffff-no-rj" />
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>TIMES NOW</span>
      </p>
        </a>

        <a href="https://www.bhaskar.com/bhaskar-khaas/positive-story/news/delhi-zobox-founder-neeraj-chopra-success-story-business-strategy-131778196.html">  
        <Card img="https://images.bhaskarassets.com/web2images/521/2020/11/db_1604320813.png" />
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>DAINIK BHASKAR</span>
      </p>
        </a>

        <a href="https://www.siliconindia.com/news/startups/zobox-indias-no-1-refurbished-gadgets-startup-to-enter-the-buyback-market-nid-225187-cid-19.html">  
        <Card img="https://content.jdmagicbox.com/comp/bangalore/j7/080pxx80.xx80.110810182506.s3j7/catalogue/silicon-india-murugeshpalya-bangalore-internet-website-developers-1771rqi.jpg" />
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>SILICON INDIA</span>
      </p>
        </a>

        <a href="https://navbharattimes.indiatimes.com/business/business-news/zobox-founder-neeraj-chopra-who-started-old-mobilephone-business-now-company-turnover-5-thousand-crore-all-details-here/articleshow/103405352.cms">  
        <Card img="https://play-lh.googleusercontent.com/De6Eb87A3dD8oppMp7iwJRa0agiejAS2NS1-t49JgfaxvWlBjQEuOLprKX5xGNLD4CM" />
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>NBT</span>
      </p>
        </a>

        <a href="https://hindi.news18.com/news/business/success-story-zobox-founder-neeraj-chopra-who-started-old-mobilephone-business-now-company-turnover-50000-crore-7445033.html">  
        <Card img="https://upload.wikimedia.org/wikipedia/commons/2/24/News_18_India.png" />
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>NEWS18</span>
      </p>
        </a>

        <a href="https://kannada.hindustantimes.com/lifestyle/success-story-zobox-founder-neeraj-chopra-inspirational-journey-selling-old-refurbished-phones-career-success-pcp-181694178117757.html">  
        <Card img="https://kannada.hindustantimes.com/assets/images/defaultimages/default-330_v1.jpg" />
        <p style={{ fontStyle: "italic", marginTop: 25 }}>
        <span style={{ fontWeight: 500, color: " #2b367f" }}>HINDUSTAN TIMES</span>
      </p>
        </a>



      


        </Slider>

      </div>
    </div>
  );
};

const Card = ({ img }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
        color: "gray",
      }}
    >
      <Avatar
        imgProps={{ style: { borderRadius: "100%" } }}
        src={img}
        style={{
          width: 200,
          height: 150,
          border: "1px solid lightgray",
          padding: 10,
          marginBottom: 20,
        }}
      />
     
      
    </div>
  );
};

export default Press;
import { FaAccessibleIcon, FaGg } from "react-icons/fa";
import { SiDatabricks } from "react-icons/si";
import { MdConnectWithoutContact } from "react-icons/md";

export const MissionList = [
  {
    id: 1,
    icon: <FaAccessibleIcon color="#0a1930" size={22} />,
    heading: "Designed for you",
    text: "At ZOBOX, creating a seamless platform for both online and offline purchases of the finest branded refurbished mobile phones is our priority. Our business model is a win-win for both customers and business owners, operating in B2B and B2C models.",
  },
  {
    id: 2,
    icon: <SiDatabricks color="#0a1930" size={22} />,
    heading: "Keep Your History",
    text: "In a nutshell, we simplify the process, offering the best brands at the best prices. ZOBOX is not just a platform; it's a commitment to quality and affordability.",
  },
  {
    id: 3,
    icon: <MdConnectWithoutContact color="#0a1930" size={22} />,
    heading: "Stay Connected",
    text: "Our team of 30 passionate individuals is here to assist you in keeping your Smartphone in top-notch condition—a device that holds immense importance in your daily life.",
  },

];

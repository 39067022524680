import { FaAccessibleIcon, FaGg } from "react-icons/fa";
import { SiDatabricks } from "react-icons/si";
import { MdConnectWithoutContact } from "react-icons/md";

export const AboutList = [
  {
    id: 1,
    icon: <FaAccessibleIcon color="#0a1930" size={22} />,
    heading: "Designed for you",
    text: "Welcome to ZOBOX, your go-to platform for both online and offline purchases of the finest branded refurbished mobile phones. Our business model is designed to benefit both customers and business owners, operating seamlessly in both B2B and B2C models.",
  },
  {
    id: 2,
    icon: <SiDatabricks color="#0a1930" size={22} />,
    heading: "Keep Your History",
    text: "Our mission is simple: to provide the best brands at the best prices. ZOBOX was conceived with this motto in mind, ensuring that you get quality devices without breaking the bank.",
  },
  {
    id: 3,
    icon: <MdConnectWithoutContact color="#0a1930" size={22} />,
    heading: "Stay Connected",
    text: "Meet our team of 30 passionate individuals, devoted to assisting you in maintaining the functionality and up-to-datedness of your beloved Smartphone—the most crucial item in your life.",
  },
  // {
  //   id: 4,
  //   icon: <FaGg color="#0a1930" size={22} />,
  //   heading: "Take control",
  //   text: "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repudiandae veniam dolorum repellendus obcaecati minus voluptas neque dolore similique.",
  // },
];
